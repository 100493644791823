let domain = "";

if (/bsms-\w+-kasse.gomus.de/.test(document.location.host)) {
  // special case for bsms, all POS go to single API endpoint
  domain = "bsms.gomus.de";
} else {
  // default behaviour strip -kasse, -integration and .demo.kasse
  domain = document.location.host.replace('.demo.kasse', '').replace('-kasse', '').replace('-integration', '');
}

export const environment = {
  production: true,
  domain: domain,
  shopDomain: document.location.host,
  environment: 'production',
  version: '4.2.5.9',
  commit_sha: '3811d0c2eecea875d4f4ffba18224656a79c8335',
  rollbarAccessToken: 'e8baa210d636404ca2e7cc5370391c9f',
};
